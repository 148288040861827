<template>
  <div class="page-update-invoice">
    <nav class="breadcrumb" aria-label="breadcrumb">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li><router-link to="/dashboard/factures">Factures</router-link></li>
        <li class="is-active"><router-link to="/dashboard/factures/edit" aria-current="true">Modifier une facture</router-link></li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Modifier Facture</h1>
      </div>

      <div class="column is-6">
        <h2 class="is-size-5 mb-4">Client</h2>
        <div class="select">
          <select v-model="invoice.client">
            <option value="">--Choisir un Client--</option>
            <option v-for="client in clients" :key="client.id" :value="client">
              {{ client.company_name }}
            </option>
          </select>
        </div>
        <div class="box mt-4" v-if="invoice.client">
          <p><strong>{{ invoice.client.company_name }}</strong></p>
          <p>{{ invoice.client.address }}</p>
          <p>{{ invoice.client.code_postal }} {{ invoice.client.ville }}</p>
          <p>{{ invoice.client.pays }}</p>
        </div>
      </div>

      <div class="column is-2">
        <h2 class="is-size-5 mb-4">Echéance</h2>
        <div class="control">
          <input type="date" class="input" v-model="invoice.due_date" required>
        </div>
      </div>

      <div class="column is-12">
        <h2 class="is-size-5 mb-4">Produits</h2>
        <ItemForm
          v-for="item in invoice.items"
          :key="item.item_num"
          :initialItem="item"
          @delete-item="removeItem"
          @updatePrice="updateTotals"
        />
        <button class="button is-light" @click="addItem"><strong>+</strong></button>
      </div>
    </div>

    <div class="column is-12" v-if="invoice.total_ht !== 0">
      <h2 class="is-size-5 mb-4">Total</h2>
      <p><strong>Total H.T.:</strong> {{ invoice.total_ht }}€</p>
      <p v-if="invoice.invoice_reduction !== 0">-Reduction: {{ invoice.invoice_reduction }}€</p>
      <p><strong>T.V.A:</strong> {{ invoice.total_tva }}€</p>
      <p v-if="invoice.tva_5 !== 0 || invoice.tva_20 !== 0">Dont:</p>
      <p v-if="invoice.tva_5 !== 0">-T.V.A 5.5%: {{ invoice.tva_5 }}€</p>
      <p v-if="invoice.tva_20 !== 0">-T.V.A 20%: {{ invoice.tva_20 }}€</p>
      <p><strong>Total T.T.C:</strong> {{ invoice.total_ttc }}€</p>
    </div>

    <div class="column is-12">
      <button class="button is-success" @click="submitForm">Enregistrer</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import { v4 as uuidv4 } from 'uuid'
import ItemForm from '@/components/ItemForm.vue'

export default {
  name: 'UpdateInvoice',
  components: { ItemForm },
  data() {
      return {
          invoice: {
              id: this.$route.params.id,
              client: '',
              /* invoice_type: '', */
              items: [
                  {
                      item_num: uuidv4(),
                      item_id: '',
                      item_name: '',
                      unit_price: '',
                      quantity: 1,
                      tva: 0,
                      total: 0,
                      item_reduction: 0,
                  }
              ],
              due_date: '',
              total_ht: 0,
              total_tva: 0,
              tva_5: 0,
              tva_20: 0,
              total_ttc: 0,
              invoice_reduction: 0
          },
          clients: []
      }
  },
  async mounted() {
    await this.getClients()
    await this.getInvoice()
  },
  methods: {
    async getClients() {
      try {
        const response = await axios.get('/api/v1/clients/')
        this.clients = response.data
      } catch (error) {
        console.error(error)
      }
    },
    async getInvoice() {
      try {
        const response = await axios.get(`/api/v1/factures/${this.invoice.id}/`)
        const invoiceData = response.data
        this.invoice = {
          ...invoiceData,
          client: this.clients.find(client => client.id === invoiceData.client) || null,
        }
      } catch (error) {
        console.error(error)
      }
    },
      addItem() {
          this.invoice.items.push({
          item_num: uuidv4(),
          item_id: '',
          item_name: '',
          unit_price: '',
          quantity: 1,
          tva: 0,
          total_ht: 0,
          item_reduction: 0,
          })
      },
      removeItem(item_num) {
        console.log(this.invoice.id)
          this.invoice.items = this.invoice.items.filter(item => item.item_num !== item_num)
          this.updateTotals()
      },
      updateTotals(changedItem) {
          let total_ht = 0
          let tva_20 = 0 
          let tva_5 = 0
          let reduction = 0

          let item = this.invoice.items.filter(i => i.item_num === changedItem.item_num)

          item = changedItem
          for (let i = 0; i < this.invoice.items.length; i++) {
              const tx_tva = this.invoice.items[i].tva
              const tx_red = this.invoice.items[i].item_reduction

              if (tx_tva == 5.5) {
                  tva_5 += this.invoice.items[i].total * (tx_tva/100)
              }
              else{
                  tva_20 += this.invoice.items[i].total * (tx_tva/100)
              }
              
              if (tx_red != 0) {
                      reduction += this.invoice.items[i].quantity * this.invoice.items[i].unit_price * (tx_red/100)
              }
              total_ht += this.invoice.items[i].total
          }

          const tva = tva_5 + tva_20;

          this.invoice.total_ht = parseFloat(total_ht).toFixed(2)
          this.invoice.total_tva = parseFloat(tva).toFixed(2)
          this.invoice.tva_20 = parseFloat(tva_20).toFixed(2)
          this.invoice.tva_5 = parseFloat(tva_5).toFixed(2)
          this.invoice.total_ttc = parseFloat((total_ht + tva)).toFixed(2)
          this.invoice.invoice_reduction = parseFloat(reduction.toFixed(2))
      },
    async submitForm() {
      try {
        const { client } = this.invoice
        this.invoice = {
          ...this.invoice,
          client_name: client.company_name,
          client_email: client.email,
          client_company: client.company,
          client_address: client.address,
          client_cp: client.code_postal,
          client_ville: client.ville,
          client_pays: client.pays,
          client: client.id,
        }

        await axios.put(`/api/v1/factures/${this.invoice.id}/`, this.invoice)
        toast({
          message: 'La facture a été mise à jour',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'top-center',
        })
        this.$router.push('/dashboard/factures')
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
.select,
.select select {
  width: 100%;
}
</style>
