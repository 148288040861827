<template>
    <div class="page-update-product">
        <nav class="breadcrumb" aria-label="breadcrumb">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/products">Produits</router-link></li>
                <li><router-link :to="{ name: 'Product', params: { id:product.id } }">{{ product.product_name }}</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'UpdateProduct', params: { id: product.id }}" aria-current="true">Modifier - {{  product.product_name  }}</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Modifier - {{  product.product_name  }}</h1>
            </div>

            <div class="column is-6">
                <div class="field">
                    <label>Ref. Produit</label>

                    <div class="control">
                        <input type="text" name="product_id" class="input" v-model="product.product_id">
                    </div>
                </div>

                <div class="field">
                    <label>Produit</label>

                    <div class="control">
                        <input type="text" name="product_name" class="input" v-model="product.product_name">
                    </div>
                </div>

                <div class="field">
                    <label>Prix Unitaire</label>

                    <div class="control">
                        <input type="number" name="product_unit_price" class="input" v-model="product.product_unit_price">
                    </div>
                </div>

                <div class="field">
                    <label>Type</label>

                    <div class="select">
                        <select name="type" v-model="product.product_type">
                            <option value="">--Choisir Type--</option>
                            <option value="Couverture">Couverture</option>
                            <option value="Tablette">Tablette</option>
                            <option value="Napolitain">Napolitain</option>
                            <option value="Divers">Divers</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="column is-6">
                <div class="field">
                    <label>tva</label>

                    <div class="select">
                        <select name="tva" v-model.number="product.tva">
                            <option value="">--Choisir Type--</option>
                            <option :value="5.5">5.5</option>
                            <option :value="20">20</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="column is-12">
                <div class="field">
                    <div class="control">
                        <button class="button is-success" @click="submitForm">Enregister</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'UpdateProduct',
    data() {
        return {
            product: {}
        }
    },
    mounted() {
        this.getProduct()
    },
    methods: {
        getProduct() {
            const productId = this.$route.params.id
            axios
                .get(`/api/v1/products/${productId}`)
                .then(response => {
                    response.data.tva = parseFloat(response.data.tva)
                    this.product = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },

        submitForm() {
            const productId = this.$route.params.id
            axios
                .patch(`/api/v1/products/${productId}/`, this.product)
                .then(response => {
                    toast({
                        message: 'Changement Enregister',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-center',
                    })
                    
                    this.$router.push('/dashboard/products')
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        }
    }
}
</script>
